import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { userToolStore, alunoStore, analyticsStore, cursoStore, mainStore } from '@/store';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import GenericListTotalCardComponent from '@/components/GenericListTotalCardComponent.vue';
import GenericListItemsCardComponent from '@/components/GenericListItemsCardComponent.vue';
import ContratoChipComponent from '@/components/ContratoChipComponent.vue';
let AlunoList = class AlunoList extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                text: 'Nome do aluno',
                value: 'aluno_nome',
                table: 'aluno',
                show: true,
                type: 'str',
            },
            {
                text: 'Contratos',
                // table: 'aluno',
                value: 'contratos',
                show: true,
            },
            {
                text: 'Idade',
                // table: 'aluno',
                value: 'idade',
                show: true,
            },
            {
                text: 'Data de nascimento do aluno',
                table: 'aluno',
                value: 'aluno_data_nascimento',
                type: 'date',
            },
            {
                text: 'CPF do aluno',
                table: 'aluno',
                value: 'aluno_cpf',
                show: true,
                type: 'cpf',
            },
            { text: 'RNE do aluno', table: 'aluno', value: 'aluno_rne', type: 'int4' },
            { text: 'RG do aluno', table: 'aluno', value: 'aluno_rg', type: 'int4' },
            {
                text: 'Órgão emissor do RG do aluno',
                table: 'aluno',
                value: 'aluno_orgao_emissor',
                type: 'str',
            },
            { text: 'Sexo do aluno', table: 'aluno', value: 'aluno_sexo', type: 'str' },
            {
                text: 'Ocupação do aluno',
                table: 'aluno',
                value: 'aluno_ocupacao',
                type: 'str',
            },
            {
                text: 'Email do aluno',
                table: 'aluno',
                value: 'aluno_email',
                type: 'str',
            },
            {
                text: 'Celular do aluno',
                table: 'aluno',
                value: 'aluno_celular',
                type: 'str',
            },
            { text: 'CEP do aluno', table: 'aluno', value: 'aluno_cep', type: 'cep' },
            {
                text: 'Endereço do aluno',
                table: 'aluno',
                value: 'aluno_rua',
                type: 'str',
            },
            {
                text: 'Número de endereço do aluno',
                table: 'aluno',
                value: 'aluno_numero',
                type: 'str',
            },
            {
                text: 'Complemento do aluno',
                table: 'aluno',
                value: 'aluno_complemento',
                type: 'str',
            },
            {
                text: 'Bairro do aluno',
                table: 'aluno',
                value: 'aluno_bairro',
                type: 'str',
            },
            {
                text: 'Código de município pelo IBGE do aluno',
                table: 'aluno',
                value: 'aluno_codigo_municipio_completo',
                type: 'int4',
            },
            {
                text: 'O aluno é o seu responsável financeiro',
                table: 'aluno',
                value: 'aluno_responsavel_financeiro',
                type: 'bool',
            },
            { text: 'Contatos do aluno', table: 'aluno', value: 'aluno_contatos' },
            {
                text: 'Nome do responsável',
                table: 'aluno',
                value: 'responsavel_nome',
                type: 'str',
            },
            {
                text: 'Data de nascimento do responsável',
                table: 'aluno',
                value: 'responsavel_data_nascimento',
                type: 'str',
            },
            {
                text: 'CPF do responsável',
                table: 'aluno',
                value: 'responsavel_cpf',
                type: 'cpf',
            },
            {
                text: 'RNE do responsável',
                table: 'aluno',
                value: 'responsavel_rne',
                type: 'str',
            },
            {
                text: 'RG do responsável',
                table: 'aluno',
                value: 'responsavel_rg',
                type: 'str',
            },
            {
                text: 'Órgão emissor do RG do responsável',
                table: 'aluno',
                value: 'responsavel_orgao_emissor',
                type: 'str',
            },
            {
                text: 'Sexo do responsável',
                table: 'aluno',
                value: 'responsavel_sexo',
                type: 'str',
            },
            {
                text: 'Ocupação do responsável',
                table: 'aluno',
                value: 'responsavel_ocupacao',
                type: 'str',
            },
            {
                text: 'Email do responsável',
                table: 'aluno',
                value: 'responsavel_email',
                type: 'str',
            },
            {
                text: 'CEP do responsável',
                table: 'aluno',
                value: 'responsavel_cep',
                type: 'str',
            },
            {
                text: 'Endereço do responsável',
                table: 'aluno',
                value: 'responsavel_rua',
                type: 'str',
            },
            {
                text: 'Número de endereço do responsável',
                table: 'aluno',
                value: 'responsavel_numero',
                type: 'str',
            },
            {
                text: 'Complemento do responsável',
                table: 'aluno',
                value: 'responsavel_complemento',
                type: 'str',
            },
            {
                text: 'Bairro do responsável',
                table: 'aluno',
                value: 'responsavel_bairro',
                type: 'str',
            },
            {
                text: 'Código de município pelo IBGE do responsável',
                table: 'aluno',
                value: 'responsavel_codigo_municipio_completo',
                type: 'str',
            },
            {
                text: 'Parentesco do responsável',
                table: 'aluno',
                value: 'responsavel_parentesco',
                type: 'str',
            },
            {
                text: 'Conferido por',
                table: 'conferido_por',
                value: 'full_name',
                type: 'str',
            },
            {
                text: 'Conferido em',
                table: 'aluno',
                value: 'conferido_em',
                type: 'timestamp',
            },
            {
                text: 'Dados da turma - Professor',
                table: 'tmp',
                value: 'dados_turma_professor',
                type: 'str',
            },
        ];
        this.carregandoTotals = false;
        this.loadingMounted = false;
    }
    get totals() {
        return analyticsStore.totals;
    }
    get criarAlunoEnabled() {
        if (this.getCurrentUser.company.permite_inserir_aluno_sem_prospex) {
            return true;
        }
        if (mainStore.userProfile.company.data_primeiro_aluno_cadastrado) {
            // @ts-ignore
            const dataCriacao = new Date(mainStore.userProfile.company.data_primeiro_aluno_cadastrado);
            const currentDate = new Date();
            // @ts-ignore
            const diffInMilliseconds = currentDate - dataCriacao;
            const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
            return Math.floor(diffInDays) <= 31;
        }
        return true;
    }
    get getCurrentUser() {
        return mainStore.userProfile;
    }
    get cursosOptions() {
        const cursos = cursoStore.cursos;
        cursos.unshift({
            id: '-1',
            value: 'Sem curso',
            nome: 'Sem curso',
        });
        return cursos;
    }
    get allowView() {
        if (userToolStore.userToolsIndexed.Aluno) {
            return userToolStore.userToolsIndexed.Aluno[0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed.Aluno) {
            return userToolStore.userToolsIndexed.Aluno[0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed.Aluno) {
            return userToolStore.userToolsIndexed.Aluno[0].allow_edit;
        }
    }
    async buscarTotals() {
        this.carregandoTotals = true;
        await this.doSearchTotals();
        this.carregandoTotals = false;
    }
    async buscar() {
        // ficava aqui quando carregava na abertura da tela
        // await this.doSearchTotals();
        analyticsStore.executeSearchItems();
    }
    async doSearchItems(queryIn) {
        return await alunoStore.getAlunos(queryIn);
    }
    async doSearchTotals() {
        return await analyticsStore.getAlunoPorContrato();
    }
    async mounted() {
        this.loadingMounted = true;
        analyticsStore.initAnalytics();
        analyticsStore.setFnSearchItems(alunoStore.getAlunos);
        analyticsStore.setHeaders(this.headers);
        // tela que nao tem defaultFilter precisa ter o buscar() aqui
        this.buscar();
        this.loadingMounted = false;
    }
};
AlunoList = __decorate([
    Component({
        components: {
            ContratoChipComponent,
            GenericListItemsCardComponent,
            GenericListTotalCardComponent,
            OpenNewTabComponent,
        },
    })
], AlunoList);
export default AlunoList;
